import styles from './Header.module.css';

export default function Header(props) {
  return (
    <>
      <img className={styles.logo} src='https://ddy1csms58ecs.cloudfront.net/images/pillars.png' />

      {!props.leaderboardActive && (
        <div onClick={props.toggleMenu} className={styles.menuToggle} data-menu-active={props.menuActive}>
          <span></span>
        </div>
      )}
    </>
  )
}