import classes from './Button.module.css';
import Spinner from './Spinner';

function Button(props) {
  return(
    <button
      onClick={props.handleClick}
      className={classes.button}
      data-id={props.id}
      data-color={props.color}
      data-state={props.state}
      data-size={props.size}
      data-layout={props.layout}
      data-shape={props.shape}
      data-role={props.role}
      disabled={props.disabled}
      type={props.type}
    >
      {(props.state === 'submitting' || props.state === 'loading') && (
        <Spinner color='black' />
      )}
      {props.children}
    </button>
  )
}

export default Button