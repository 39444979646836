import Button from '../UI/Button'
import IconClose from '../UI/Icons/IconClose'
import styles from './VideoPlayer.module.css'

export default function VideoPlayer(props) {
  return (
    <div className={styles.wrapper}>
      <Button handleClick={props.close} layout='text'>CLOSE <IconClose /></Button>
      <div className={styles.iframeWrapper}>
        <iframe src={`https://www.youtube.com/embed/${props.video_id}`} title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerPolicy="strict-origin-when-cross-origin" allowFullScreen></iframe>
      </div>
    </div>
  )
}