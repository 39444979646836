import styles from './Loading.module.css'

export default function Loading(props) {
  return(
    <div className={styles.wrapper}>
      <div className={styles.bar}>
        <div className={styles.text}>
          LOADING...
        </div>
      </div>
    </div>
  )
}