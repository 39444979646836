
import IconClose from './Icons/IconClose';
import classes from './Modal.module.css'
import Spinner from './Spinner';

export default function Modal(props) {
  return(
    <div className={classes.wrapper} data-active={props.active} data-size={props.size} data-show-status={props.showStatus} data-backdrop-blur={props.blur_backdrop}>
      <div className={classes.background} onClick={props.showStatus ? null : props.close}></div>
      <div className={classes.content}>
        
        {(props.header || props.close) && (
          <div className={classes.header}>
            <div>{props.header}</div>
            <span onClick={props.close} className={classes.close}><IconClose /></span>
          </div>
        )}

        <div className={classes.body}>
          {props.children}
        </div>

        {props.showStatus && (props.status === 'submitting' || props.status === 'success') && (  
          <div className={classes.loadingCover}>
            {props.status === 'submitting' && (
              <>
                <Spinner color='black' />
                Processing
              </>
            )}
            
          </div>
        )}
      </div>
      
    </div>
  )
}