import styles from './Spinner.module.css'

export default function Spinner(props) {
  return(
    <div className={styles.wrapper} data-color={props.color} data-size={props.size}>
      <svg viewBox="0 0 50 50">
        <circle cx="25" cy="25" r="20" fill="none" strokeWidth="5"></circle>
      </svg>
      <div>
        {props.message}
      </div>
    </div>
  )
}