export default function IconAppleMusic() {
  return (
    <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg"
      x="0px" y="0px" viewBox="0 0 384 512">
      <title>Apple Music Logo</title>
      <path className="st0" d="M318.7,268.7c-0.2-36.7,16.4-64.4,50-84.8c-18.8-26.9-47.2-41.7-84.7-44.6c-35.5-2.8-74.3,20.7-88.5,20.7
        c-15,0-49.4-19.7-76.4-19.7C63.3,141.2,4,184.8,4,273.5c0,26.2,4.8,53.3,14.4,81.2c12.8,36.7,59,126.7,107.2,125.2
        c25.2-0.6,43-17.9,75.8-17.9c31.8,0,48.3,17.9,76.4,17.9c48.6-0.7,90.4-82.5,102.6-119.3C315.2,329.9,318.7,270.6,318.7,268.7
        L318.7,268.7z M262.1,104.5c27.3-32.4,24.8-61.9,24-72.5c-24.1,1.4-52,16.4-67.9,34.9c-17.5,19.8-27.8,44.3-25.6,71.9
        C218.7,140.8,242.5,127.4,262.1,104.5z"/>
      </svg>
  )
}