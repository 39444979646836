'use client'

import { useState } from "react";
import Menu from "./Menu";
import Header from "./Header";

export default function Nav(props) {
  const [menuActive, setMenuActive] = useState(false);

  const toggleMenu = () => {
    setMenuActive(!menuActive);
  }

  const changePlatform = (platform) => {
    props.handleNavigate(platform)
    setMenuActive(false);
  }

  return (
    <>
      <Menu menuActive={menuActive} changePlatform={changePlatform} phase={props.phase} />
      <Header toggleMenu={toggleMenu} menuActive={menuActive} />
    </>
  )
}