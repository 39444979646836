export const products = [
  {
    id: 1,
    name: 'Icon Rising Mineral Wash Boyfriend Tee',
    mesh_id: 'merch_tshirt-1',
    url: 'https://shop.parishilton.com/products/infinite-icon-bf-tee-w-mineral-wash'
  },
  {
    id: 2,
    name: 'Interplanetary Tee',
    mesh_id: "merch_tshirt-2",
    url: 'https://shop.parishilton.com/products/infinite-icon-pillar-tee'
  },
  {
    id: 3,
    name: 'Iridescent Clear Belt Bag',
    mesh_id: "merch_fanny-pack",
    url: '	https://shop.parishilton.com/products/concert-fanny-belt-bag'
  },
  {
    id: 4,
    name: 'Bad Bitch Academy Crop Tank',
    mesh_id: 'merch_tank',
    url: 'https://shop.parishilton.com/products/bba-crop-tank'
  },
  {
    id: 5,
    name: 'Infinite Icon CD',
    mesh_id: 'merch_cd',
    url: 'https://shop.parishilton.com/products/cd'
  },
  {
    id: 6,
    name: 'Infinite Icon Pink Vinyl LP',
    mesh_id: "merch_vinyl",
    url: 'https://shop.parishilton.com/products/pink-vinyl-lp'
  },
  {
    id: 7,
    name: 'Icon Black Hoodie',
    mesh_id: "merch_hoodie",
    url: 'https://shop.parishilton.com/products/infinite-icon-hoodie-w-back-pillars'
  },
  {
    id: 8,
    name: 'Infinite Icon Trucker Hat',
    mesh_id: "merch_hat",
    url: 'https://shop.parishilton.com/products/trucker-hat'
  }
]


// export const products = [
//   {
//     id: 1,
//     name: 'Infinite Icon Trucker Hat',
//     url: 'https://shop.parishilton.com/products/trucker-hat'
//   },
//   {
//     id: 2,
//     name: 'Interplanetary Tee',
//     url: 'https://shop.parishilton.com/products/infinite-icon-pillar-tee'
//   },
//   {
//     id: 3,
//     name: 'Iridescent Clear Belt Bag',
//     url: '	https://shop.parishilton.com/products/concert-fanny-belt-bag'
//   },
//   {
//     id: 4,
//     name: 'Bad Bitch Academy Crop Tank',
//     url: 'https://shop.parishilton.com/products/bba-crop-tank'
//   },
//   {
//     id: 5,
//     name: 'Infinite Icon CD',
//     url: 'https://shop.parishilton.com/products/cd'
//   },
//   {
//     id: 6,
//     name: 'Infinite Icon Pink Vinyl LP',
//     url: 'https://shop.parishilton.com/products/pink-vinyl-lp'
//   },
//   {
//     id: 7,
//     name: 'Bad Bitch Academy Fleece Varsity Jacket',
//     url: 'https://shop.parishilton.com/products/bba-varsity-jacket'
//   },
//   {
//     id: 8,
//     name: 'Icon Black Hoodie',
//     url: 'https://shop.parishilton.com/products/infinite-icon-hoodie-w-back-pillars'
//   },
//   {
//     id: 9,
//     name: 'Icon Rising Mineral Wash Boyfriend Tee',
//     url: 'https://shop.parishilton.com/products/infinite-icon-bf-tee-w-mineral-wash'
//   }
// ]