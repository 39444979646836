export default function IconSpotify() {
  return (
    <svg fill="currentColor" version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 496 512">
      <title>Spotify Logo</title>
      <path d="M248,8C111.1,8,0,119.1,0,256s111.1,248,248,248s248-111.1,248-248S384.9,8,248,8z M348.7,372.9
        c-4.2,0-6.8-1.3-10.7-3.6c-62.4-37.6-135-39.2-206.7-24.5c-3.9,1-9,2.6-11.9,2.6c-9.7,0-15.8-7.7-15.8-15.8
        c0-10.3,6.1-15.2,13.6-16.8c81.9-18.1,165.6-16.5,237,26.2c6.1,3.9,9.7,7.4,9.7,16.5S356.8,372.9,348.7,372.9L348.7,372.9z
          M375.6,307.3c-5.2,0-8.7-2.3-12.3-4.2c-62.5-37-155.7-51.9-238.6-29.4c-4.8,1.3-7.4,2.6-11.9,2.6c-10.7,0-19.4-8.7-19.4-19.4
        s5.2-17.8,15.5-20.7c27.8-7.8,56.2-13.6,97.8-13.6c64.9,0,127.6,16.1,177,45.5c8.1,4.8,11.3,11,11.3,19.7
        C394.9,298.6,386.5,307.3,375.6,307.3z M406.6,231.1c-5.2,0-8.4-1.3-12.9-3.9c-71.2-42.5-198.5-52.7-280.9-29.7
        c-3.6,1-8.1,2.6-12.9,2.6c-13.2,0-23.3-10.3-23.3-23.6c0-13.6,8.4-21.3,17.4-23.9c35.2-10.3,74.6-15.2,117.5-15.2
        c73,0,149.5,15.2,205.4,47.8c7.8,4.5,12.9,10.7,12.9,22.6C429.8,221.4,418.8,231.1,406.6,231.1L406.6,231.1z"/>
    </svg>
  )
}